import { useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import logoBlack from "../../image/logo/logo_black_150x45.png";
import logoWhite from "../../image/logo/logo_white150x45.png";

import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { MHidden } from "../../component/@material-extend";
import { Context } from "../../context";
// import { Email } from "@mui/icons-material";
import HeaderMobileMenu from "./HeaderMobileMenu";
import GooglePlay from "../../image/android-download.svg";
import AppStore from "../../image/app-store.png";
import MobileAppInstallWidget from "./MobileAppInstallWidget";
import Constant from "../../util/constants";
import "./Header.css";
import { StyledButton } from "../../component/common/Styled";

const { paths } = Constant;

const Header = () => {
  const { user, auth } = useContext(Context);
  const { userDetail } = user;
  const { authenticated } = auth;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky">
      <MHidden width="smUp">
        <MobileAppInstallWidget />
      </MHidden>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ alignItems: "center", justifyContent:"space-between", gap:{xs: "12px", lg:0} }}>
          <Link
            underline="none"
            component={RouterLink}
            to="/home"
            style={{ borderRight: "1px solid var(--app-base)" }}
          >
            <img
              src={logoWhite}
              alt={logoBlack}
              style={{ width: 150, height: 45, marginRight: "10px" }}
            />
          </Link>

          <MHidden width="mdDown">
            <Box className="cm-apps" sx={{  display: "flex", mx:5 }}>
              <a
                href={process.env.REACT_APP_IOS_APP_STORE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Get it on App Store</span>
                <picture>
                  <source media="" type="image/svg" />
                  <img
                    className="image"
                    src={AppStore}
                    alt="Get it on App Store"
                    loading="lazy"
                  />
                </picture>
              </a>
            </Box>
            <Box className="cm-apps" sx={{  display: "flex", mr:5 }}>
              <a
                href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Get it on Google Play</span>
                <picture>
                  <source media="" type="image/svg" />
                  <img
                    className="image"
                    src={GooglePlay}
                    alt="Get it on Google Play"
                    loading="lazy"
                  />
                </picture>
              </a>
              </Box>

          </MHidden>
          <MHidden width="smDown">
            <Box className="cm-contact" sx={{ flexGrow: 1, justifyContent: {xs: "flex-end", lg:"left"}}}>
              <StyledButton
                variant="contained" 
                href="https://calendly.com/oazaenergy/30min"
                target="_blank"
                style={{backgroundColor:'var(--app-base)',
                   color:'var(--app-color-dark)'}}
              >
                Schedule Demo
              </StyledButton>
            </Box>
          </MHidden>  
          <Box sx={{display: 'flex', gap: 1 }} className="nav">
            <MHidden width="lgDown">
              <Link
                underline="none"
                className={`nav-item ${
                  window.location.pathname === paths.HOME && "active"
                }`}
                component={RouterLink}
                to={paths.HOME_}
              >
                <Button sx={{ my: 2, color: "white" }}>Home</Button>
              </Link>
              <Link
                underline="none"
                className={`nav-item ${
                  window.location.pathname === paths.PRODUCTS && "active"
                }`}
                component={RouterLink}
                to={paths.PRODUCTS}
              >
                <Button sx={{ my: 0, color: "white" }}>Products</Button>
              </Link>
              <Link
                underline="none"
                component={RouterLink}
                className={`nav-item ${
                  window.location.pathname === paths.WHY_OAZA && "active"
                }`}
                to={paths.WHY_OAZA}
              >
                <Button sx={{ my: 0, color: "white" }}>Why Oaza</Button>
              </Link>
              <Link
                underline="none"
                className={`nav-item ${
                  window.location.pathname === paths.FEEDBACK && "active"
                }`}
                component={RouterLink}
                to={paths.FEEDBACK}
              >
                <Button sx={{ my: 0, color: "white" }}>Feedback</Button>
              </Link>
              {/* <Link underline="none" component={RouterLink} to="/about-us">
                <Button sx={{ my: 0, color: "white" }}>About us</Button>
              </Link> */}
              <Link
                underline="none"
                className={`nav-item ${
                  window.location.pathname === paths.CONTACT_US && "active"
                }`}
                component={RouterLink}
                to={paths.CONTACT_US}
              >
                <Button sx={{ my: 0, color: "white" }}>Contact us</Button>
              </Link>
              {/* <HeaderWebMenu /> */}
            </MHidden>
            {!authenticated.isAuth && (
              <Link underline="none" className="nav-item" component={RouterLink} to="/login">
                <Button sx={{ my: 0, color: "white" }}>Login</Button>
              </Link>
            )}
            {authenticated.isAuth && (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={userDetail.name}
                      src={userDetail.photoId ? userDetail.photoId :"/static/images/avatar/2.jpg"}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Link
                    to="/account"
                    component={RouterLink}
                    className="popover-menu"
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">Account</Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    to="/request-charger"
                    component={RouterLink}
                    className="popover-menu"
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">
                        Charger Request
                      </Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    to="/change-password"
                    component={RouterLink}
                    className="popover-menu"
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">
                        Change password
                      </Typography>
                    </MenuItem>
                  </Link>
                  <Link
                    to="/logout"
                    component={RouterLink}
                    className="popover-menu"
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Link>
                </Menu>
              </>
            )}
            <MHidden width="lgUp">
              <HeaderMobileMenu />
            </MHidden>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
